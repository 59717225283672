// ** React Imports
import { Suspense, lazy } from 'react'
import ReactDOM from 'react-dom'
import 'antd/dist/antd.css'

// ** Redux Imports
import { Provider } from 'react-redux'
import { store } from './redux/storeConfig/store'

// ** Toast & ThemeColors Context
import { ToastContainer } from 'react-toastify'
import { ThemeContext } from './utility/context/ThemeColors'

// ** Spinner (Splash Screen)
import Spinner from './@core/components/spinner/Fallback-spinner'

// ** Ripple Button
import './@core/components/ripple-button'

// ** PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/components/prism-jsx.min'

import axios from 'axios'

// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css'

// ** React Toastify
import '@styles/react/libs/toastify/toastify.scss'

// ** Core styles
import './@core/assets/fonts/feather/iconfont.css'
import './@core/scss/core.scss'
import './assets/scss/style.scss'

// ** Service Worker
import * as serviceWorker from './serviceWorker'

// ** Lazy load app
const LazyApp = lazy(() => import('./App'))

import AuthMng, {getToken} from './utility/oAuth'
import { isJwtExpired } from 'jwt-check-expiration'


axios.interceptors.response.use((response) => {
  return response
}, (error) => {
  if (error.response.status === 401) {
    const auth = new AuthMng()
    localStorage.setItem('lastSeenPage', location.href)
    if (getToken() === '') {
      window.location = `${process.env.REACT_APP_BASENAME}/login`
    }
    if (isJwtExpired(getToken())) {
      window.location = `${process.env.REACT_APP_BASENAME}/login`
    } else {
      auth.signinRedirectPrompt()
    }
  } else {
    return Promise.reject(error)
  }
})

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<Spinner />}>
      <ThemeContext>
        <LazyApp />
        <ToastContainer newestOnTop />
      </ThemeContext>
    </Suspense>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
