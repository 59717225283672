import { UserManager, WebStorageStateStore, Log } from 'oidc-client'
import { IDENTITY_CONFIG, METADATA_OIDC } from '../@core/components/OidcComponent/oidcsettings'
import jwtDecode from 'jwt-decode'

export default class AuthService {
  UserManager

  constructor(props) {
    this.UserManager = new UserManager({
      ...IDENTITY_CONFIG,
      userStore: new WebStorageStateStore({ store: window.sessionStorage }),
      metadata: {
        ...METADATA_OIDC
      }
    })
    // Logger
    Log.logger = console
    Log.level = Log.DEBUG
    // eslint-disable-next-line no-unused-vars
    this.UserManager.events.addUserLoaded((user) => {
      const payloadAction = {
        id: user.profile.sub,
        name: user.profile.name,
        role: jwtDecode(user.access_token).role ? jwtDecode(user.access_token).role : [],
        email: user.profile.email,
        authorized: true,
        token: user.access_token,
        company: user.profile.customer_name,
        customerId: jwtDecode(user.access_token).customer_id
      }
      try {
        this.UserManager.startSilentRenew()
        localStorage.setItem('user', JSON.stringify(payloadAction))
      } catch (e) {
        console.log(e)
      }
      if (window.location.href.indexOf('signin-oidc') !== -1) {
        this.navigateToScreen()
      }
    })
    this.UserManager.events.addSilentRenewError((e) => {
      console.log(e)
    })

    this.UserManager.events.addAccessTokenExpired(() => {
      this.signinSilent()
    })
  }

  signinRedirectCallback = () => {
    this.UserManager.signinRedirectCallback().then(() => {})
  }

  getUser = async () => {
    const user = await this.UserManager.getUser()
    if (!user) {
      // eslint-disable-next-line no-return-await
      return await this.UserManager.signinRedirectCallback()
    }
    return user
  }

  parseJwt = (token) => {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace('-', '+').replace('_', '/')
    return JSON.parse(window.atob(base64))
  }

  signinRedirect = () => {
    localStorage.setItem('redirectUri', window.location.pathname)
    this.UserManager.signinRedirect({})
  }

  signinRedirectPrompt = () => {
    localStorage.setItem('redirectUri', window.location.pathname)
    this.UserManager.signinRedirect({prompt: 'login'})
  }

  navigateToScreen = () => {
    const page = localStorage.getItem('lastSeenPage')
    if (page && page !== '') {
      window.location.replace(page)
      localStorage.removeItem('lastSeenPage')
    } else {
      window.location.replace(`${process.env.REACT_APP_REDIRECT_PATH}`)
    }
  }

  isAuthenticated = () => {
    const oidcStorage = JSON.parse(
      sessionStorage.getItem(
        `oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`
      )
    )

    return !!oidcStorage && !!oidcStorage.access_token
  }

  signinSilent = () => {
    this.UserManager.signinSilent()
      .then((user) => {})
      .catch((err) => {})
  }

  signinSilentCallback = () => {
    this.UserManager.signinSilentCallback()
  }

  createSigninRequest = () => {
    return this.UserManager.createSigninRequest()
  }

  logout = () => {
    this.UserManager.signoutRedirect({
      id_token_hint: localStorage.getItem('id_token')
    })
    this.UserManager.clearStaleState()
    localStorage.clear()
  }

  signoutRedirectCallback = () => {
    localStorage.clear()
    this.UserManager.clearStaleState()
    this.UserManager.signoutRedirectCallback().then(() => {
      window.location.replace(`${process.env.REACT_APP_ORIGIN}`)
    })
  }
}

export const getToken = () => {
  try {
    const json = JSON.parse(localStorage.getItem('user'))
    if (json) return json.token
  } catch (e) {
    return ''
  }
  return ''
}

export const getRoles = () => {
  try {
    const json = JSON.parse(localStorage.getItem('user'))
    if (json) return json.role
  } catch (e) {
    return []
  }
  return []
}

export const getUser = () => {
  try {
    const json = JSON.parse(localStorage.getItem('user'))
    if (json) return json
  } catch (e) {
    return null
  }
  return null
}

export const isAuthorized = () => {
  try {
    const json = JSON.parse(localStorage.getItem('user'))
    if (json) return json.authorized
  } catch (e) {
    return false
  }
  return false
}
